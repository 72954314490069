import { Router } from "next/router";
import React from "react";
import * as Sentry from "@sentry/nextjs";
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI

    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    console.log({ error, errorInfo });
    const ErrorMessage = error?.message;
    const Location = errorInfo?.componentStack;
    console.log(ErrorMessage, Location);
    Sentry.captureException(new Error(`${ErrorMessage}${Location}`));
  }
  redirectFunc() {
    this.setState({ hasError: false });
    // Router.push("/");
    Router.reload();
  }
  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "100vh",
          }}
        >
          <div>
            <h2>Oops, there is an error!</h2>
          </div>
          <div>
            <button type="button" onClick={() => this.redirectFunc()}>
              Try again?
            </button>
          </div>
        </div>
      );
    }

    // Return children components in case of no error

    return this.props.children;
  }
}

export default ErrorBoundary;
