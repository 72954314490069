const IbrDetailReducers = (state, action) => {
  switch (action.type) {
    case 'SET_IBR_DATA':
      return {
        ibrDetail: action.payload,
        isFetching: false,
        error: false,
      }
    case 'REMOVE_IBR_DATA':
      return {
        ibrDetail: null,
        isFetching: false,
        error: false,
      }
    default:
      return state
  }
}

export default IbrDetailReducers
