// import 'antd/dist/reset.css'
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect } from "react";
import AdminLayout from "../components/AdminLayout/layout";
import { AuthContextProvider } from "../context/AuthContext";
import { IbrDetailContextProvider } from "../context/IbrDetailContext/IbrDetailContext";
import { SchoolContextProvider } from "../context/schoolContext/SchoolContext";
import "../styles/index.scss";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import * as Sentry from "@sentry/nextjs";
import ErrorBoundary from "../components/ErrorBoundry";

function MyApp({ Component, pageProps }) {
  useEffect(() => {
    Sentry.init({
      dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
      tracesSampleRate: 1.0,
    });
  }, []);

  if (Component.getLayout) {
    return Component.getLayout(<Component {...pageProps} />);
  }

  return (
    <>
      <AuthContextProvider>
        <GoogleOAuthProvider clientId="299899089685-8o2bvmesjcgm9nv60sqlf5fuuh34t1ou.apps.googleusercontent.com">
          <SchoolContextProvider>
            <IbrDetailContextProvider>
              <Head>
                <title>Edly</title>
                <meta
                  name="description"
                  content="Edly allows investors to invest in the education of students and earn a percentage of the students' future income."
                />
                <link
                  href="https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,300,800,900"
                  rel="stylesheet"
                  type="text/css"
                />
                <link rel="icon" href="/Logo.svg" />
              </Head>

              <AdminLayout>
                <ErrorBoundary>
                  <Component {...pageProps} />
                </ErrorBoundary>
              </AdminLayout>
            </IbrDetailContextProvider>
          </SchoolContextProvider>
        </GoogleOAuthProvider>
      </AuthContextProvider>
    </>
  );
}

export default MyApp;
