import { createContext, useEffect, useReducer } from 'react'
import IbrDetailReducers from './IbrDetailReducers'

const INITIAL_STATE = {
  ibrDetail:
    typeof window != 'undefined'
      ? JSON.parse(window.localStorage.getItem('ibrDetail'))
      : null,
}

export const IbrDetailContext = createContext(INITIAL_STATE)

export const IbrDetailContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(IbrDetailReducers, INITIAL_STATE)
  useEffect(() => {
    localStorage.setItem('ibrDetail', JSON.stringify(state))
  }, [state])

  return (
    <IbrDetailContext.Provider
      value={{
        ibrDetail: state?.ibrDetail,
        dispatch,
      }}
    >
      {children}
    </IbrDetailContext.Provider>
  )
}
