const SchoolReducer = (state, action) => {
  switch (action.type) {
    case 'TERMS_REQUEST_SUCCESS':
      return {
        user: action.payload,
        isFetching: false,
        error: false,
      }
    case 'TERMS_REQUEST_FAILED':
      return {
        user: null,
        isFetching: false,
        error: false,
      }

    default:
      return state
  }
}

export default SchoolReducer
