import { createContext, useEffect, useReducer } from 'react'
import AuthReducer from './AuthReducers'

const INITIAL_STATE = {
  user:
    typeof window != 'undefined'
      ? JSON.parse(window.localStorage.getItem('userAdminInfo'))
      : null,
}

export const AuthContext = createContext(INITIAL_STATE)

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE)
  useEffect(() => {
    localStorage.setItem('userAdminInfo', JSON.stringify(state.user))
  }, [state?.user])

  return (
    <AuthContext.Provider
      value={{
        user: state?.user,
        dispatch,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
