import { Layout } from "antd";
import React, { useEffect } from "react";
// import { changeToWhiteBg } from '../../utils/global'
import Sidebar from "./sidebar";

const { Header, Content, Footer, Sider } = Layout;

const AdminLayout = ({ children }) => {
  // const {
  //   token: { colorBgContainer },
  // } = theme.useToken()

  useEffect(() => {
    // changeToWhiteBg()
  }, []);

  return (
    <Layout className="student-admin-dashboard">
      <Sidebar />
      <Layout>
        <Content className="admin-main-layout-content">
          <div
            style={{
              // padding: 24 ,
              // minHeight: 360,
              background: "#ffffff",
            }}
          >
            {children}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};
export default AdminLayout;
