import { UpOutlined } from "@ant-design/icons";
import { Avatar, Col, Dropdown, Layout, Menu, Row, Tooltip } from "antd";
import "antd/dist/antd.css";
import Image from "next/image";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import edlyLogo from "../../public/assets/auth/img/Logo-white.svg";
import admin from "../../public/assets/sidebar/admin.svg";
import side_logo from "../../public/assets/sidebar/graduate.svg";

import { useRouter } from "next/router";
import { logoutHandler } from "../../utils/handler";
const { Sider } = Layout;
const { SubMenu } = Menu;

const Sidebar = ({ children }) => {
  const router = useRouter();
  const { user } = useContext(AuthContext);

  const [collapsed, setCollapsed] = useState(false);
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");

  const menu = (
    <Menu
      items={[
        {
          label: "Log Out",
          key: "0",
          onClick: () => {
            router.push(
              `${process.env.NEXT_PUBLIC_STUDENT_ADMIN}/login`,
              logoutHandler()
            );
          },
        },
      ]}
    />
  );
  const subMenuItems = [
    {
      key: "sub1",
      label: "Investors",
      icon: <Image src={side_logo} alt="logo" />,
      children: [
        {
          key: "2",
          label: "Orders",
          onClick: () =>
            router.push(
              `${process.env.NEXT_PUBLIC_INVESTOR_DOMAIN}/investor-admin/orders`
            ),
        },
        {
          key: "3",
          label: "Investors",
          onClick: () =>
            router.push(
              `${process.env.NEXT_PUBLIC_INVESTOR_DOMAIN}/investor-admin/investors`
            ),
        },
        {
          key: "4",
          label: "Offerings",
          onClick: () =>
            router.push(
              `${process.env.NEXT_PUBLIC_INVESTOR_DOMAIN}/investor-admin/offerings`
            ),
        },
        {
          key: "5",
          label: "Athletes",
          onClick: () =>
            router.push(
              `${process.env.NEXT_PUBLIC_INVESTOR_DOMAIN}/investor-admin/athletes`
            ),
        },
        {
          key: "6",
          label: "Nilly Schools",
          onClick: () =>
            router.push(
              `${process.env.NEXT_PUBLIC_INVESTOR_DOMAIN}/investor-admin/nilly-school`
            ),
        },
        {
          key: "7",
          label: "Requested Schools",
          onClick: () =>
            router.push(
              `${process.env.NEXT_PUBLIC_INVESTOR_DOMAIN}/investor-admin/requested-school`
            ),
        },
      ],
    },
    {
      key: "sub2",
      label: "Students",
      icon: <Image src={side_logo} alt="logo" />,
      children: [
        {
          key: "7",
          label: "All Students",
          onClick: () =>
            router.push(
              `${process.env.NEXT_PUBLIC_STUDENT_ADMIN}/student/overview`
            ),
        },
        // {
        //   key: '6',
        //   label: 'Documents',
        //   onClick: () =>
        //     router.push(`${process.env.NEXT_PUBLIC_STUDENT_ADMIN}/documents`),
        // },
        // {
        //   key: '7',
        //   label: 'Terms',
        //   onClick: () =>
        //     router.push(`${process.env.NEXT_PUBLIC_STUDENT_ADMIN}/terms`),
        // },
        {
          key: "8",
          label: "School",
          onClick: () =>
            router.push(`${process.env.NEXT_PUBLIC_STUDENT_ADMIN}/school`),
        },
        {
          key: "9",
          label: "Assign Investor",
          onClick: () =>
            router.push(
              `${process.env.NEXT_PUBLIC_STUDENT_ADMIN}/assign-investor`
            ),
        },
        {
          key: "10",
          label: "System Health",
          onClick: () =>
            router.push(
              `${process.env.NEXT_PUBLIC_STUDENT_ADMIN}/system-health`
            ),
        },
        process.env.NEXT_PUBLIC_ENV == "production" && {
          key: "10.1",
          label: "Reporting",
          onClick: () =>
            router.push(`${process.env.NEXT_PUBLIC_STUDENT_ADMIN}/reporting`),
        },
        {
          key: '23',
          label: 'Terms',
          onClick: () =>
            router.push(
              `${process.env.NEXT_PUBLIC_STUDENT_ADMIN}/import-terms`
            ),
        },
      ],
    },
    // {
    //   key: 'sub5',
    //   label: 'Primary Sheet',
    //   icon: <FileTextOutlined />,
    //   children: [
    //     {
    //       key: '18',
    //       label: 'Servicers',
    //       onClick: () =>
    //         router.push(
    //           `${process.env.NEXT_PUBLIC_PRIMARY_SHEET_DOMAIN}/primary-sheet/servicers`
    //         ),
    //     },
    //     {
    //       key: '19',
    //       label: 'Pools',
    //       onClick: () =>
    //         router.push(
    //           `${process.env.NEXT_PUBLIC_PRIMARY_SHEET_DOMAIN}/primary-sheet/pools`
    //         ),
    //     },
    //     {
    //       key: '20',
    //       label: 'Generated Sheets',
    //       onClick: () =>
    //         router.push(
    //           `${process.env.NEXT_PUBLIC_PRIMARY_SHEET_DOMAIN}/primary-sheet/pools/generated-sheets`
    //         ),
    //     },
    //     {
    //       key: '21',
    //       label: 'Servicer Uploads',
    //       onClick: () =>
    //         router.push(
    //           `${process.env.NEXT_PUBLIC_PRIMARY_SHEET_DOMAIN}/primary-sheet/servicer-uploads`
    //         ),
    //     },
    //     {
    //       key: '22',
    //       label: 'Applications',
    //       onClick: () =>
    //         router.push(
    //           `${process.env.NEXT_PUBLIC_PRIMARY_SHEET_DOMAIN}/primary-sheet/applications`
    //         ),
    //     },
    //   ],
    // },
    {
      key: "sub3",
      label: "Admin",
      icon: <Image src={admin} alt="logo" />,
      children: [
        {
          key: "11",
          label: "Admin List",
          onClick: () =>
            router.push(`${process.env.NEXT_PUBLIC_STUDENT_ADMIN}/admin`),
        },
        {
          key: "13",
          label: "Enquiry",
          onClick: () =>
            router.push(
              `${process.env.NEXT_PUBLIC_STUDENT_ADMIN}/admin/enquery`
            ),
        },
        // {
        //   key: '11',
        //   label: 'Admin List1',
        //   onClick: () =>
        //     router.push(`${process.env.NEXT_PUBLIC_STUDENT_ADMIN}/admin/list`),
        // },
        {
          key: "12",
          label: "Role List",
          onClick: () =>
            router.push(`${process.env.NEXT_PUBLIC_STUDENT_ADMIN}/admin/roles`),
        },
        // {
        //   key: '13',
        //   label: 'Client',
        //   onClick: () =>
        //     router.push(
        //       `${process.env.NEXT_PUBLIC_STUDENT_ADMIN}/admin/client-list`
        //     ),
        // },
        // {
        //   key: '14',
        //   label: 'Setting',
        //   onClick: () =>
        //     router.push(
        //       `${process.env.NEXT_PUBLIC_STUDENT_ADMIN}admin/settings`
        //     ),
        // },
        // {
        //   key: '15',
        //   label: 'Enquiry',
        //   onClick: () =>
        //     router.push(
        //       `${process.env.NEXT_PUBLIC_STUDENT_ADMIN}/admin/enquery`
        //     ),
        // },
      ],
    },
    {
      key: "sub4",
      label: "Certificate School",
      icon: <Image src={admin} alt="logo" />,
      children: [
        {
          key: "16",
          label: (
            <Tooltip
              placement="top"
              title={`Certificate School Staff's List`}
              arrow={false}
            >
              <span>Certificate School Staff{`'`}s List</span>
            </Tooltip>
          ),
          onClick: () => router.push("/coding-school"),
        },
        {
          key: "17",
          label: (
            <Tooltip
              placement="top"
              title={`Landing Certificate School List`}
              arrow={false}
            >
              <span>Landing Certificate School List</span>
            </Tooltip>
          ),
          onClick: () => router.push("/add-coding-school-landing"),
        },
      ],
    },
  ];

  const localStorageFunction = () => {
    localStorage.getItem("userAdminInfo") &&
      (setFirst_name(
        JSON.parse(localStorage.getItem("userAdminInfo"))?.user?.first_name
      ),
        setLast_name(
          JSON.parse(localStorage.getItem("userAdminInfo"))?.user?.last_name
        ));
  };
  useEffect(() => {
    localStorageFunction();
  }, [router]);

  return (
    <Row className="main-wrapper-row">
      <Sider
        // collapsible
        breakpoint="lg"
        collapsedWidth="0"
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        className="admin-dashboard-sidebar"
      >
        <div className="sidebar-head">
          <Image src={edlyLogo} alt="logo" />
          <p className="logo-caption">Admin Portal</p>
        </div>

        <div className="scroll-bar">
          <Menu
            theme="dark"
            mode="inline"
            className="menu-list"
            items={subMenuItems}
          ></Menu>
        </div>
        {first_name && (
          <>
            <div className="stu-admin-bottom-user-div">
              <span>
                <Avatar size={24} className="stu-admin-user-sider-avatar">
                  {first_name} {last_name}
                </Avatar>
              </span>
              <span className="stu-admin-sider-user-arrow">
                <span className="stu-admin-sider-username">
                  <span>
                    {first_name} {last_name}
                  </span>
                </span>
                <Dropdown
                  overlay={menu}
                  overlayClassName="dropdown-box"
                  trigger={["click"]}
                  placement="topRight"
                  className="logout-dropdown"
                >
                  <UpOutlined />
                </Dropdown>
              </span>
            </div>
          </>
        )}
      </Sider>

      <Col flex="auto" className="children-content">
        {children}
      </Col>
    </Row>
  );
};

export default Sidebar;
