import { deleteCookie } from 'cookies-next'
import Router from 'next/router'

const logoutHandler = () => {
  deleteCookie('access_token')
  deleteCookie('refresh_token')
  localStorage.removeItem('userAdminInfo')
  sessionStorage.clear()
  localStorage.clear()
}

const handleRequest = (res) => {
  let responseError = res?.response?.status

  if (responseError === 403) {
    logoutHandler()
    Router.push('/login')
  }
}

export { handleRequest, logoutHandler }
