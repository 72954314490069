import { createContext, useEffect, useReducer } from 'react'
import SchoolReducer from './SchoolReducers'

const INITIAL_STATE = {
  schoolTerm:
    typeof window != 'undefined' &&
    window.localStorage.getItem('schoolTerm') !== undefined
      ? window.localStorage.getItem('schoolTerm') == undefined &&
        JSON.parse(window.localStorage.getItem('schoolTerm'))
      : null,
}

export const SchoolContext = createContext(INITIAL_STATE)

export const SchoolContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(SchoolReducer, INITIAL_STATE)
  useEffect(() => {
    localStorage.setItem('schoolTerm', JSON.stringify(state))
    // console.log(state,"flag")
  }, [state])

  return (
    <SchoolContext.Provider
      value={{
        schoolTerm: state,
        dispatch,
      }}
    >
      {children}
    </SchoolContext.Provider>
  )
}
